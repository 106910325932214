import * as React from "react"
import { graphql } from "gatsby";
import styled from "styled-components"
import { Container, Row, Col } from "react-awesome-styled-grid"

import { ThemeProvider } from "styled-components";
import { ContentstackEmailForm } from "../Riot/EmailForm";

import Input from "../Commons/Input/index"
import Button from "../Commons/Button/index"
import Switch from "../Commons/Switch/index"
import Title from "../Commons/Title"
import Paragraph from "../Commons/Paragraph"
import DatePicker from "react-datepicker"

import {
  Section,
  Content,
  DescriptionWrapper,
  FormContainer,
} from "./styles";

export const fragment = graphql`
  fragment NewsletterSignupFragment on Contentstack_home_2_0 {
    newsletterSignup: newsletter_signup {
      emailListName: email_list_name
      salesForceExternalKey: salesforce_external_key
      headline
      flavorCopy: flavor_copy
      ctaLabel: cta_label
      termsCopy: terms_copy
      emailFieldLabel: email_field_label
      emailValidationMessage: email_validation_message
      dobLabel: dob_label
      dobValidationMessage: dob_validation_message
      successMessage: success_message
      failureMessage: failure_message
    }
  }
`

export interface NewsletterData {
  headline: string
  flavorCopy: string
  ctaLabel: string
  termsCopy: string
  emailFieldLabel: string
  emailValidationMessage: string
  dobLabel: string
  dobValidationMessage: string
  successMessage: string
}

type NewsletterType = {
  data: NewsletterData
  locale: string
  desktopBG: string
  laptopBG: string
  tabletBG: string
  mobileBG: string
  animation_class: string
}

const Newsletter = (props: NewsletterType) => {
  const {
    headline,
    flavorCopy,
    ctaLabel,
    termsCopy,
    emailFieldLabel,
    emailValidationMessage,
    dobLabel,
    dobValidationMessage: _dobValidationMessage,
    successMessage,

  } = props.data;

  const { locale } = props;

  let locationPathname = "/"
  if (typeof window !== "undefined") {
    locationPathname = window.location.pathname
  }

  // American English dob format is MM/DD/YYYY.
  // Anything else is DD/MM/YYYY.
  // Use a vertical bar to add a localized dob format to
  // dobValidationMessage. D-M-Y order must be preserved.
  // Examples for specifying dob format:
  //
  // "Ingresa una fecha de nacimiento válida. | DD/MM/AAAA"
  // "Veuillez entrer une date de naissance valide. | JJ/MM/AAAA"
  // "Введите дату рождения. | ДД/ММ/ГГГГ"
  // "請輸入有效的出生日期。| 日/月/西元年 (DD/MM/YYYY)"

  let dobFormat = (locale == "en-us") ? "MM/DD/YYYY" : "DD/MM/YYYY"
  let dobValidationMessage = _dobValidationMessage

  if (locale != "en-us" && dobValidationMessage.includes("|")) {
    [dobValidationMessage, dobFormat] = dobValidationMessage.split(/ *\| */)
  }


  return (
    <Section
      className="lazyload"
      ie_sm={props.mobileBG}
      ie_md={props.tabletBG}
      ie_lg={props.laptopBG}
      ie_xl={props.desktopBG}
      data-bgset={`${props.desktopBG} [(min-width: 2560px)] | ${props.laptopBG} [(min-width: 1440px)] | ${props.tabletBG} [(min-width: 768px)] | ${props.mobileBG} [(min-width: 320px)] | ${props.mobileBG}`}
    >
      <Container>
        <Row justify="center">
          <Col xs={2} sm={8} md={10} lg={10}>
            <Content>
              <Title
                className={props?.animation_class}
                level={1}
                color="cream"
                uppercase
              >
                {headline}
              </Title>
              <DescriptionWrapper className={props?.animation_class}>
                <Paragraph color="">{flavorCopy}</Paragraph>
              </DescriptionWrapper>
              <FormContainer>
                <ThemeProvider
                  theme={{
                    email_form_background_image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII="
                  }}
                >
                  <ContentstackEmailForm
                    globalConfig={{
                      date_of_birth_field_label: dobLabel,
                      date_of_birth_format: dobFormat,
                      email_field_label: emailFieldLabel,
                      error_messages: {
                        invalid_dob: dobValidationMessage,
                        invalid_email: emailValidationMessage,
                      },
                      post_signup_header: successMessage,
                    }}
                    lang={props.locale}
                    pageConfig={{
                      age_gating_enabled: true,
                      email_cta: ctaLabel,
                      email_list_name: 'Riot Forge Publication List',
                      salesforce_external_key: 'RiotAccountData',
                      subhead: '',
                      title: '',
                      successURL: `${locationPathname}#Newsletter`,
                    }}
                  />
                </ThemeProvider>
                <p className="legal" dangerouslySetInnerHTML={{ __html: termsCopy }}/> 

              </FormContainer>
            </Content>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Newsletter
