import { isValid, parse, differenceInYears } from "date-fns";
import { enGB, enUS } from "date-fns/locale";

export const isBirthdayValid = (formattedBirthday: string, locale: string) => {
  // is birthdate field formatted properly?
  const validFormatRegex = /\d{2}\/\d{2}\/\d{4}/;
  if (!formattedBirthday || !validFormatRegex.test(formattedBirthday)) {
    return false;
  }

  let parsedDate = parse(formattedBirthday, "P", new Date(), {
    locale: locale === "en-us" ? enUS : enGB,
  });

  // is date valid?
  if (!isValid(parsedDate)) {
    return false;
  }

  // is the user at least 13 years old?
  let diff = differenceInYears(new Date(), parsedDate);

  if (!diff || diff < 13) {
    return false;
  }

  return true;
};
