import styled from "styled-components";

export const ButtonWrapper = styled.div`
  margin: 2vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    letter-spacing: 2px;
  }
  a {
    display: flex;
    align-items: center;
    margin-top: 1.25vw;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #30353f;
    margin-left: 0;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 14px;
    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
      font-size: 16px;
    }
    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      font-size: 20px;
    }
    &:hover {
      color: #4a5160;
      svg path {
        fill: #4a5160;
      }
    }
  }
  svg {
    margin-left: 10px;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    flex-direction: row;
    a {
      margin-top: 0.208vw;
    }
    .textLink {
      margin-left: 24px;
    }
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    flex-direction: row;
    margin-top: 0.833vw;
    margin-bottom: 0.833vw;
    a {
      margin-top: 0.208vw;
    }
  }
  @media (max-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    button {
      span {
        padding: 8px 32px;
      }
    }
  }
`;
export const HeadingWrapper = styled.div`
  margin: 30px 0 20px;
  min-height: 80px;
  h1 {
    text-align: center;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    margin: 20px 0 10px;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.xl}rem) {
    margin: 44px 0 10px;
  }
`;

export const Section = styled.section`
  position: relative;
  padding: 8px 0 0px 0;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 24px 0 0px 0;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    padding: 3vh 0 0px 0;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.xl}rem) {
    padding: 3vh 0 0px 0;
  }
`;

type Section = {
  ie_sm: string;
  ie_md: string;
  ie_lg: string;
  ie_xl: string;
};

export const SectionBreaker = styled.div<Section>`
  position: absolute;
  height: 60%;
  width: 100%;
  top: 0;
  background-size: 150% auto;
  background-repeat: no-repeat;
  background-position: 0 -111px;
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(86% - 179px), 40.2% calc(86% - 56px), 41.25% calc(86% - 106px), 0% 86%);
  background-size: cover;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    background-position: top;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.xl}rem) {
    background-size: 125% auto;
  }
  @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    background-image: url(${(props) => props.ie_sm});
  }
  @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    background-image: url(${(props) => props.ie_md});
  }
  @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    background-image: url(${(props) => props.ie_lg});
  }
  @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.xl}rem) {
    background-image: url(${(props) => props.ie_xl});
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const WrapperRight = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const VideoArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VideoContainer = styled.div`
  width: 60vh;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    width: 35vh;
    max-width: 350px;
    min-width: 300px;
  }
`;

export const PlatformList = styled.ul`
  display: block;
  text-align: center;
  margin: 1vh 0;
  max-width: 350px;
`;
export const PlatformListItem = styled.li`
  display: inline-block;
  padding: 0 8px;
  svg {
    width: auto;
    max-width: 112px;
    height: 28px;
    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      max-width: 96px;
      height: 32px;
    }
  }
  svg path {
    fill: #30353f;
  }
  &:nth-child(1) {
    svg {
      max-width: 71px;
      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
        max-width: 76px;
      }
    }
  }
  &:nth-child(2) {
    svg {
      max-width: 112px;
      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
        max-width: 135px;
      }
    }
  }
  &:nth-child(5) {
    svg {
      max-width: 112px;
      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
        max-width: 134px;
      }
    }
  }
  &:nth-child(6) {
    svg {
      max-width: 75px;
      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
        max-width: 89px;
      }
    }
  }
`;

export const ParagraphWrapper = styled.div`
  text-align: center;
  min-height: 100px;
  margin-bottom: 3vh;
  width: 100%;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-bottom: 0;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    margin-bottom: 10px;
    min-height: 80px;
  }
`;

export const ImagesWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0px 20px 0px;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    position: relative;
    display: block;
    margin: 0;
  }
`;

type IeImage = {
  ie_image: string;
};

export const LogoImage = styled.div<IeImage>`
  width: 100%;
  max-width: 200px;
  margin-right: auto;
  margin-left: 16px;
  img {
    width: 100%;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    max-width: 125px;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-left: 80px;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-left: 0;
    position: absolute;
    max-width: 270px;
    width: 22vw;
    top: -20px;
    right: -100px;
  }
  @media (max-width: 1200px) and (min-height: 1200px) {
    right: -20px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background-image: url(${(props) => props.ie_image});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
  }
`;

export const FrontImage = styled.div<IeImage>`
  display: none;
  img {
    width: 100%;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    display: block;
    position: absolute;
    bottom: -16vw;
    right: -9vw;
    width: 100%;
    max-width: 50vw;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    right: 9vw;
    max-width: 45vw;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    bottom: -60px;
    left: -60px;
    width: 100%;
    max-width: 60vh;
    min-width: 500px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background-image: url(${(props) => props.ie_image});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
  }
`;

export const SwiperWrapper = styled.div`
  display: block;
  position: relative;
  z-index: 5;
`;

export const ThumbItem = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    width: 28px;
    margin: 0 10px;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    width: 46px;
    margin: 0 12px;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    width: 46px;
    margin: 0 12px;
  }
`;

export const NextButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  right: 10px;
  -webkit-transform: translate(100%, 0%);
  -ms-transform: translate(100%, 0%);
  transform: translate(100%, 0%);
  color: #8b8682;
  opacity: 1;
  pointer-events: initial !important;
  left: auto;
`;

export const PrevButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  right: auto;
  -webkit-transform: translate(100%, 0%);
  -ms-transform: translate(100%, 0%);
  transform: translate(100%, 0%);
  color: #8b8682;
  opacity: 1;
  pointer-events: initial !important;
  left: -56px;
`;

export const Number = styled.span`
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
`;

type ProgressType = {
  active: boolean;
  isStop: boolean;
  second: number | string;
  percentage?: number | string;
};

export const Progress = styled.div<ProgressType>`
  width: 46px;
  height: 6px;
  transform: skew(-20deg);
  background-color: ${(props) => props.theme.variables.secondary.base};
  margin-top: 0.208vw;
  @keyframes progress-animation {
    0% {
      width: 0px;
    }
    100% {
      width: 100%;
    }
  }
  &:after {
    content: "";
    height: 100%;
    background: white;
    ${(props) => props.active && props.isStop && "width: 100%;"}
    ${(props) =>
      props.active &&
      !props.isStop &&
      `
      -webkit-animation: progress-animation ${props.second ?? 5}s linear;
      animation: progress-animation ${props.second ?? 5}s linear;
      animation-fill-mode: forwards;
    `}
    
    // width: ${(props) => props.active && props.percentage}%;
    z-index: 10;
    position: absolute;
    left: 0;
    // transition: width 1s ease-in-out;
  }
`;

export const ThumbWrapper = styled.div`
  .swiper-wrapper {
    align-items: center;
    justify-content: center;
  }
  .swiper-slide {
    width: auto !important;
  }
  .swiper-container {
    overflow: initial;
    position: absolute;
    transform: translate(-50%, 1.25vw);
    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
      left: 52%;
    }
    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
      left: 50%;
    }
    @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
      left: 50%;
    }
  }
  .swiper-container-thumbs {
    .swiper-slide {
      color: ${(props) => props.theme.variables.secondary.base};
    }
    .swiper-slide.swiper-slide-thumb-active {
      color: ${(props) => props.theme.variables.secondary.cream};
    }
    .swiper-button-prev {
      cursor: pointer;
      left: 0;
      transform: translate(-100%, 0%);
      color: ${(props) => props.theme.variables.secondary.base};
      opacity: 1;
      pointer-events: initial !important;
      &::after {
        font-size: 14px;
      }
      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
        left: -36px;
      }
    }
    .swiper-button-next {
      cursor: pointer;
      right: 0;
      transform: translate(100%, 0%);
      color: ${(props) => props.theme.variables.secondary.base};
      opacity: 1;
      pointer-events: initial !important;
      &::after {
        font-size: 14px;
      }
      @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
        right: -36px;
      }
    }
  }
`;
