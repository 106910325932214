import styled, { css } from "styled-components";
import Title from "../Commons/Title";

type ForgeType = {
  logo: string;
};

type SwiperWrapperProps = {
  active: boolean;
}

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 75px;
  ${Title} {
    z-index: 2;
  }
`;

export const AnimationContainer = styled.div`
  
`

export const SwiperWrapper = styled.div<SwiperWrapperProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-left: 20px;
  max-width: calc(100% - 40px);
  opacity: ${props => props.active ? 1 : 0};
  transition: opacity 300ms ease-out;

  @media (min-width: 1440px) {
    margin-left: calc((100% - 1190px) / 2);
    max-width: calc(100% - calc((100% - 1190px) / 2));
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .swiper-slide,
  .swiper-slide-active {
    display: flex;
    flex-direction: column;
    width: calc(50% - 16px) !important;

    @media (min-width: 1440px) {
      width: 578px !important;
    }

    a {
      display: flex;
      flex: 1;
    }
  }

  .swiper-scrollbar {
    background: #d6d6d6;
  }

  .swiper-container {
    padding-bottom: 40px;
  }

  .swiper-container .swiper-scrollbar {
    background-color: #4a5160;
    border-radius: 0;
    bottom: 0px;
    display: block !important;
    height: 7px;
    left: 0;
    right: 0;
    width: 100%;

    @media (min-width: 1440px) {
      width: 1200px;
    }
  }

  .swiper-container .swiper-scrollbar .swiper-scrollbar-drag {
    background-color: #f1ede5;
    border-radius: 0;
  }
`;

export const MobileCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const MobileCard = styled.div`
  display: flex;
  width: 100%;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    width: 50%;
  }
`;

export const Forge = styled.div<ForgeType>`
  background-image: url("${(props) => props.logo}");
  background-repeat: no-repeat;
  background-size: cover;
  width: 59px;
  height: 110px;
  position: absolute;
  left: 50%;
  top: -34%;
  transform: translate(-50%, 0);
  z-index: 1;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    width: 60px;
    height: 115px;
  }
`;

type SectionType = {
  clipPath_lg?: any;
  clipPath_md?: any;
  clipPath_sm?: any;
};

export const Section = styled.section<SectionType>`
  padding: 120px 0 50px;
  background-color: ${(props) => props.theme.variables.secondary.smoke};
  clip-path: polygon(0% 0%, 100% 0%, 100% 96%, 0% 100%);
  position: relative;
  z-index: 4;
  clip-path: ${(props) => props.clipPath_sm};

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 150px 0 100px;
    clip-path: ${(props) => props.clipPath_md};
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    padding: 150px 0 160px;
    clip-path: ${(props) => props.clipPath_lg};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px 0;

  button {
    letter-spacing: 2px;
  }
  svg {
    margin-left: 10px;
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    flex-direction: row;

    .textLink {
      margin-left: 24px;
    }
  }

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    flex-direction: row;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  @media (max-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    button {
      span {
        padding: 8px 32px;
      }
    }
  }
`;
