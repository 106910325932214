// If you don't want to use TypeScript you can delete this file!
import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar } from "swiper/core";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { Container, Row, Col } from "react-awesome-styled-grid";
import useWindowResize from "../../hooks/use-window-resize";
SwiperCore.use([Navigation, Pagination]);
import NewsCard, { NEWS_CARD_TYPE_CLASSES } from "../../components/NewsCard/index";
import Title from "../Commons/Title";
import Button from "../Commons/Button";
import ForgeLogo from "../../images/forge-shape-alt2.png";

import * as S from "./styles";

SwiperCore.use([Scrollbar]);

const clipPath_lg = `polygon(
    0% 0%,
    100% 0%,
    100% calc(100% - 119px),
    70.2% calc(100% - 56px),
    71.25% calc(100% - 146px),
    0% 100%
  )`;

const clipPath_md = `polygon(
    0% 0%,
    100% 0%,
    100% calc(100% - 122px),
    0% 100%
  )`;

const clipPath_sm = `polygon(
    0% 0%,
    100% 0%,
    100% calc(100% - 46px),
    0% 100%
  )`;

export const fragment = graphql`
  fragment NewsModuleFragment on Contentstack_home_2_0 {
    newsModule: news_module {
      title
      news {
        uid
        id
        banner {
          featured_banner_url {
            url
          }
          small_carousel_url {
            url
          }
        }
        date
        category {
          title
          machine_name
        }
        title
        description
        url {
          url
        }
        external_link
      }
    }
  }
`;

export type NewsItem = {
  uid: string;
  id: string;
  banner: {
    featured_banner_url: {
      url: string;
    };
    small_carousel_url: {
      url: string;
    };
  };
  date: string;
  category: [
    {
      title: string;
      machine_name: string;
    },
  ];
  title: string;
  description: string;
  url: {
    url: string;
  };
  external_link: string;
};

export type NewsModuleData = {
  title: string;
  news: NewsItem[];
};

export type Props = {
  data: NewsModuleData;
  list?: Array<{
    animation_class: string;
    title: string;
    category: {
      title: string;
      machine_name: string;
    };
    banner: string;
    date: string;
    description: string;
    url: {
      url: string;
    };
    external_link: string;
  }>;
};

const NewsModule: React.FC<Props> = (props: Props) => {
  const [isDesktop, setIsDesktop] = useState<boolean | undefined>(undefined);
  const [swiperActive, setSwiperActive] = useState(false);
  const { title, news } = props.data;
  const { t } = useTranslation("news");
  const { language } = useI18next();
  const newsLink = `/${language}/news/`;

  // make sure that window is available before calling window.innerWidth
  useEffect(() => {
    if (window === undefined) return;
    setIsDesktop(window.innerWidth >= 1000);
  }, []);

  useWindowResize(() => setIsDesktop(window.innerWidth >= 1000));

  const list = news?.map((news) => ({
    animation_class: "animation-opacity",
    id: news.id,
    uid: news.uid,
    title: news.title,
    banner: news.banner,
    date: news.date,
    description: news.description,
    category: news.category,
    url: {
      url: news.url?.url,
    },
    external_link: news.external_link,
  }));

  const renderCard = (newsItem: NewsItem, index: number) => (
    <NewsCard 
      key={index}
      newsCardType={NEWS_CARD_TYPE_CLASSES.newsCarousel}
      {...newsItem}
      index={index}
      cardType="small"
    />
  );

  const renderNewsMobile = () => {
    return (
      <S.MobileCardWrapper>
        {list?.map((listItem, index) => {
          return <S.MobileCard key={index}>{renderCard(listItem, index)}</S.MobileCard>;
        })}
      </S.MobileCardWrapper>
    );
  };

  const renderNewsDesktop = () => {
    return (
      <S.AnimationContainer>
        <S.SwiperWrapper active={swiperActive}>
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={32}
            initialSlide={0}
            scrollbar={{ draggable: true, dragSize: 426 }}
            onInit={() => {
              setSwiperActive(true);
            }}
            onBeforeDestroy={() => {
              setSwiperActive(false);
            }}
          >
            {list?.map((listItem, index) => {
              return <SwiperSlide key={index}>{renderCard(listItem, index)}</SwiperSlide>;
            })}
          </Swiper>
        </S.SwiperWrapper>
      </S.AnimationContainer>
    );
  };

  const renderNews = () => {
    if (isDesktop === undefined) return;
    return isDesktop ? renderNewsDesktop() : renderNewsMobile();
  };

  return (
    <S.Section clipPath_lg={clipPath_lg} clipPath_md={clipPath_md} clipPath_sm={clipPath_sm}>
      <Container>
        <Row justify="center">
          <Col xs={2} sm={2} md={8} lg={12} xl={12} align="center">
            <S.TitleWrapper className="animation-opacity">
              <S.Forge logo={ForgeLogo} />
              <Title level={1} variable="primary" color="white" uppercase>
                {t("latest-news")}
              </Title>
            </S.TitleWrapper>
          </Col>
        </Row>
      </Container>

      {renderNews()}

      <S.ButtonWrapper>
        <Button size="large" type={"quaternary"} as="a" href={newsLink}>
          <p>{t("view")}</p>
        </Button>
      </S.ButtonWrapper>
    </S.Section>
  );
};

export default NewsModule;
