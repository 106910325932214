// If you don't want to use TypeScript you can delete this file!
import React, { useEffect } from "react";
import { PageProps, graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout";
import Seo, { SeoData } from "../components/Seo/Seo";
import Hero, { HeroData } from "../components/Hero";
import GamesModule, { GamesModuleData } from "../components/GamesModule";
import HalfAndHalf, { HalfAndHalfData } from "../components/HalfAndHalf";
import Newsletter, { NewsletterData } from "../components/NewsletterModule/index";
import NewsModule, { NewsModuleData } from "../components/NewsModule";
import NewsletterDesktop from "../images/newsletter-desktop.jpg";
import Newsletterlaptop from "../images/newsletter-laptop.jpg";
import NewsletterTablet from "../images/newsletter-tablet.jpg";
import NewsletterMobile from "../images/newsletter-mobile.jpg";
import { useSiteMetadata } from "../hooks/use-site-metadata";

interface PageData {
  home: {
    homeSeo: SeoData;
    heroCarousel: HeroData;
    gamesModule: GamesModuleData;
    riverFlow: HalfAndHalfData[];
    newsletterSignup: NewsletterData;
    newsModule: NewsModuleData;
  };
}

interface Props extends PageProps<PageData> {}

const IndexPage: React.FC<Props> = ({ data }) => {
  useEffect(() => {
    if (window !== undefined) {
      require("lazysizes/plugins/attrchange/ls.attrchange.js");
      require("lazysizes/plugins/bgset/ls.bgset");
      require("lazysizes");
    }
  }, []);

  const { homeSeo, heroCarousel, gamesModule, riverFlow, newsletterSignup, newsModule } = data.home;

  const { siteDomain } = useSiteMetadata();
  const { language } = useI18next();

  const homeUrl = `https://${siteDomain}/${language}/`;

  return (
    <Layout>
      <Seo data={homeSeo} url={homeUrl} locale={language} />

      <Hero data={heroCarousel} />

      <GamesModule data={gamesModule} />

      <NewsModule data={newsModule} />

      {riverFlow.map((section, index) => (
        <HalfAndHalf key={index} data={section} first={index == 0} />
      ))}

      <Newsletter
        data={newsletterSignup}
        locale={language}
        mobileBG={NewsletterMobile}
        tabletBG={NewsletterTablet}
        laptopBG={Newsletterlaptop}
        desktopBG={NewsletterDesktop}
        animation_class="animation-height"
      />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    home: contentstackHome20(locale: { eq: $language }) {
      ...HomeSeoFragment
      ...HeroCarouselFragment
      ...GamesModuleFragment
      ...NewsModuleFragment
      ...RiverFlowFragment
      ...NewsletterSignupFragment
    }

    ...TranslationQueryFragment
  }
`;
