import React, { useState } from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-awesome-styled-grid"
import LeftImage from "../../images/modal/modalImage.png"
import Title from "../Commons/Title"
import Button from "../Commons/Button"
import { PopupClose, CloseIcon } from "../MediaGallery/style"

const Section = styled.section`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 30px 0;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 130px 0 90px 0;
  }

  background-color: ${props => props.theme.variables.primary.black};
`

const ImageWrapper = styled.div`
  text-align: center;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.sm}rem) {
    margin-bottom: 32px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-bottom: 0px;
  }
`

const Image = styled.img`
  width: 100%;
`

const SelectEditionWrapper = styled.div`
  margin-bottom: 32px;

  & > h1 {
    margin-bottom: 24px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-bottom: 64px;
  }
`

const RadioInputWrapper = styled.div`
  height: 56px;
  background-color: ${props => props.theme.variables.primary.white};
  margin-bottom: 32px;
  width: 100%;

  label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    input {
      background: color;
      &:checked ~ h1 {
        color: ${props => props.theme.variables.error[500]};
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        outline: 2px solid ${props => props.theme.variables.primary.white};
        border: 2px solid ${props => props.theme.variables.primary.black};
      }
    }
  }
`

const RadioInput = styled.input`
  width: 100%;
  display: inline;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  display: none;

  &:not(last-child) {
    margin-bottom: 24px;
  }
`

const SelectPlatformWrapper = styled.div`
  margin-bottom: 32px;

  & > h1 {
    margin-bottom: 24px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-bottom: 64px;
  }
`
const SelectInput = styled.select`
  cursor: pointer;
  width: 100%;
  padding-top: 19.5px;
  padding-bottom: 19.5px;
  padding-left: 24px;
  color: ${props => props.theme.variables.secondary.base};
`

const ButtonWrapper = styled.div``

type ModalType = {
  isModelOpen: boolean
  setIsModelOpen: (arg: boolean) => void
  selectOptions: {
    key: string
    value: string
  }[]
  radioOptions: {
    value: string
  }[]
}

const Modal = (props: ModalType) => {
  const { isModelOpen, setIsModelOpen, selectOptions, radioOptions } = props
  const [selectedSelectOption, setSelectedSelectOption] = useState(null)
  const [selectedRadioOption, setSelectedRadioOption] = useState(null)

  const handleKeyPress = (e: { keyCode: number }) => {
    if (isModelOpen) {
      if (e.keyCode === 27) {
        setIsModelOpen(false)
      }
    }
  }

  document.addEventListener("keydown", handleKeyPress)

  return (
    <Section>
      <Container>
        <Row justify="center">
          <Col sm={8} md={6} lg={5}>
            <ImageWrapper
            // leftImage={LeftImage}
            >
              <Image src={LeftImage} />
            </ImageWrapper>
          </Col>
          <Col
            sm={8}
            md={6}
            lg={6}
            offset={{
              sm: 0,
              md: 0,
              lg: 1,
            }}
          >
            <SelectEditionWrapper>
              <Title level={3} color="cream" uppercase>
                select edition
              </Title>
              {radioOptions.map((radio, index) => (
                <RadioInputWrapper>
                  <label htmlFor={`RadioInputWrapper-${index}`}>
                    <RadioInput
                      type="radio"
                      name="radioInput"
                      id={`RadioInputWrapper${index}`}
                      value={radio.value}
                      onChange={(e: any) =>
                        setSelectedRadioOption(e.target.value)
                      }
                    />
                    <Title color="" style={{ paddingLeft: "24px" }} level={5}>
                      {radio.value}
                    </Title>
                  </label>
                </RadioInputWrapper>
              ))}
            </SelectEditionWrapper>
            <SelectPlatformWrapper>
              <Title level={3} color="cream" uppercase>
                select edition
              </Title>
              <SelectInput
                // value={selectedSelectOption}
                onChange={(e: any) => setSelectedSelectOption(e.target.value)}
              >
                {selectOptions.map(o => (
                  <option key={o.key} value={o.value}>
                    {o.value}
                  </option>
                ))}
              </SelectInput>
            </SelectPlatformWrapper>
            <ButtonWrapper>
              <Button
                size="large"
                type="tertiary"
                onClick={() =>
                  setIsModelOpen(
                    selectedSelectOption && selectedRadioOption ? false : true
                  )
                }
              >
                Buy Now
              </Button>
            </ButtonWrapper>
          </Col>
        </Row>
      </Container>
      <PopupClose onClick={() => setIsModelOpen(false)}>
        <Button size="medium" type="secondary">
          <CloseIcon />
        </Button>
      </PopupClose>
    </Section>
  )
}

export default Modal
