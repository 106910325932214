import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from "react-awesome-styled-grid";
import { MobileView, DesktopView } from "../Styled";
import Button from "../Commons/Button";
import Title from "../Commons/Title";
import Paragraph from "../Commons/Paragraph";
import * as S from "./styles";

export const fragment = graphql`
  fragment RiverFlowFragment on Contentstack_home_2_0 {
    riverFlow: river_flow_content {
      alignment
      headline
      bodyCopy: body_copy
      ctaLink: cta_link {
        title
        href
      }
      foregroundImage: foreground_image {
        url
      }
      lBgDesktopImage: l_bg_desktop_image {
        url
      }
    }
  }
`;

export type HalfAndHalfData = {
  alignment: string;
  headline: string;
  bodyCopy: string;
  ctaLink: {
    title: string;
    href: string;
  };
  foregroundImage: {
    url: string;
  };
  lBgDesktopImage: {
    url: string;
  };
};

type OtherType = {
  data: HalfAndHalfData;
  first: boolean;
};

const HalfAndHalf = (props: OtherType) => {
  const { alignment, headline, bodyCopy, ctaLink, foregroundImage, lBgDesktopImage } = props.data;

  const leftAlignment = alignment == "left";
  const query = leftAlignment ? S.leftQuery : S.rightQuery;
  const offset = leftAlignment ? S.leftOffset : S.rightOffset;
  const clipPath_lg = leftAlignment ? S.leftClipPath_lg : S.rightClipPath_lg;
  const clipPath_md = leftAlignment ? S.leftClipPath_md : S.rightClipPath_md;
  const clipPath_sm = leftAlignment ? S.leftClipPath_sm : S.rightClipPath_sm;
  const halfBreaker = leftAlignment;
  const reverse = leftAlignment;
  const bg_color = leftAlignment ? "#ffffff" : "#efefef";
  const bg_layer = lBgDesktopImage ? lBgDesktopImage.url : "";

  const title = headline;
  const description = bodyCopy;
  const cta_text = ctaLink.title;
  const cta_link = ctaLink.href;

  const desktopBG = foregroundImage ? foregroundImage.url : "";
  const laptopBG = foregroundImage ? foregroundImage.url : "";
  const tabletBG = foregroundImage ? foregroundImage.url : "";

  return (
    <S.Section
      laptopBG={laptopBG}
      desktopBG={desktopBG}
      reverse={reverse}
      first={props.first}
      clipPath_lg={clipPath_lg}
      clipPath_md={clipPath_md}
      clipPath_sm={clipPath_sm}
      className="lazyload"
      bg_layer={bg_layer}
      halfBreaker={halfBreaker}
      bg_color={bg_color}
    >
      <MobileView>
        <S.MobileImageWrapper className="animation-opacity">
          <S.PhoneView>
            <S.MobileImage first={props.first} ie_image={tabletBG}>
              <img className="lazyload noselect" data-src={tabletBG} />
            </S.MobileImage>
          </S.PhoneView>
          <S.TabletView>
            <S.MobileImage first={props.first} ie_image={tabletBG}>
              <img className="lazyload noselect" data-src={tabletBG} />
            </S.MobileImage>
          </S.TabletView>
        </S.MobileImageWrapper>
      </MobileView>
      <Container>
        <Row justify={reverse ? "flex-start" : "flex-end"}>
          <Col {...query} offset={{ ...offset }}>
            <S.Wrapper className="animation-up-gallery">
              <Title color="" level={1} uppercase>
                {title}
              </Title>
              <S.DescriptionWrapper>
                <Paragraph color="base">{description}</Paragraph>
              </S.DescriptionWrapper>
              <S.ButtonWrapper>
                <Button size="large" type="primary" as="a" href={cta_link}>
                  {cta_text}
                </Button>
              </S.ButtonWrapper>
            </S.Wrapper>
          </Col>
        </Row>
      </Container>
    </S.Section>
  );
};

export default HalfAndHalf;
