import React from "react";
import { FormErrorMessage, MessageWrapper } from "./style";
//import SVGIcon from "../../assets/diamond.svg";

export interface ErrorProps {
  error: string;
}

export const Error: React.FC<ErrorProps> = ({ error }) => {
  return (
    <MessageWrapper>
      <FormErrorMessage>
        <div className={error ? "img-container active" : "img-container"}>
          {/*<SVGIcon />*/}
        </div>
        {error}
      </FormErrorMessage>
    </MessageWrapper>
  );
};

export default Error;
