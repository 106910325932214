import styled from "styled-components";
import { StyledForm, StyledField, StyledButton } from "../Riot/EmailForm/style";
import { FormErrorMessage, MessageWrapper } from "../Riot/EmailForm/components/Error/style";
import { variables } from "../layout"

const breakpoints = {
  mobileS: "320px",
  mobile: "767px",
  tablet: "1200px",
  laptop: "1440px",
  desktop: "2560px"
}

type Section = {
  ie_sm: string
  ie_md: string
  ie_lg: string
  ie_xl: string
}

export const Section = styled.section<Section>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0a282d;
  margin-top: -154px;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    background-image: url(${(props) => props.ie_sm});
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    background-image: url(${(props) => props.ie_md});
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    background-image: url(${(props) => props.ie_lg});
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: ${(props) => props.theme.awesomegrid.breakpoints.xl}rem) {
    background-image: url(${(props) => props.ie_xl});
  }
`

export const Content = styled.section`
  padding-top: 220px;
  padding-bottom: 92px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    padding-top: 250px;
    padding-bottom: 100px;
  }
`

export const DescriptionWrapper = styled.div`
  margin-top: 32px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-top: 16px;
  }
`

export const FormContainer = styled.div`
  position: relative;
  display: block;
  margin-top: 64px;


  ${StyledForm} {
    display: block;
    padding: 0;

    .post-signup {
      width: 100%;
      height: initial;
      min-width: initial;
      max-width: initial;
      min-height: initial;
      padding: 0;
      border: 0;
      background: transparent;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
      font-family: inherit;
    }

    .post-signup-content {
      width: 100%;
      padding: 48px 72px;
      background: ${variables.secondary.smoke};
      border: 4px solid ${variables.secondary.mushroom};
      box-shadow: 8px 8px 0 ${variables.secondary.mushroom};

      h1 {
        font-size: 22px;
      }

      @media (max-width: ${breakpoints.mobile}) {
        padding: 28px 42px;
        min-height: 300px;

        h1 {
          font-size: 18px;
        }
      }
    }

    .signup {
      display: flex;
      align-items: center;
      width: 100%;
      height: inherit;
      min-width: 100%;
      max-width: 100%;
      min-height: inherit;
      padding: 0;
      border: 0;
      background: transparent;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-family: inherit;

      h1 {
        display: none;
      }
    }
  }

  ${StyledField} {
    margin: 0;
    padding: 0;
    flex: 2;
    display: block;
    margin-right: 24px;
    margin-bottom:10px;

    label {
      color: ${variables.secondary.base};
    }

    label.floating {
      opacity: 0.6;
    }

    input[type="text"] {
      display: block;
      height: 54px;
      border: none;
      border-radius: 0;
      background-color: ${variables.secondary.cream};
      font-family: "proxima-nova";
      font-weight: 700;
      font-size: 17px;
      letter-spacing: 0;
      color: ${variables.secondary.grey};
      -webkit-text-fill-color: ${variables.secondary.grey};
      -webkit-box-shadow: 0 0 0px 1000px ${variables.secondary.cream} inset;
      transition: all 0.1s ease-out;
      outline: 3px solid transparent;
      outline-offset: -2px;

      :focus {
        border: 0;
        border-radius: 0;
        outline: 3px solid ${variables.secondary.base};
        outline-offset: -2px;
      }

      ::placeholder {
        font-family: "proxima-nova";
        font-weight: 700;
        font-size: 17px;
      }

      :-webkit-autofill::first-line {
        font-family: "proxima-nova";
        font-weight: 700;
        font-size: 17px;
      }
    }

    input[type="text"].error {
      outline: 3px solid ${variables.error[500]};
      outline-offset: -2px;

      @media (max-width: ${breakpoints.mobile}) {
        margin-bottom: 10px;
      }
    }

    @media (max-width: ${breakpoints.mobile}) {
      flex: 1 0 100%;
    }
  }

  ${StyledButton} {
    margin: 0;
    border-radius: 0;

    display: inline-block;
    font-family: "proxima-nova";
    font-weight: 900;
    font-size: 20px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    padding: 12px 32px;
    cursor: pointer;
    position: relative;

    // Default is lime theme
    color: ${variables.primary.white};
    border: 4px solid ${variables.primary.white};
    box-shadow: 4px 4px ${variables.primary.white};

    background: ${variables.secondary.smoke};

    transition: opacity 0.1s ease-out;

    &:hover {
      background: ${variables.secondary.grey};
      opacity: 1;
    }
    &:active {
    }
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
      box-shadow: none;
    }
  }

  ${MessageWrapper} {
    @media (max-width: ${breakpoints.mobile}) {
      position: relative;
    }
  }

  .legal {
   
    color:#F1EDE5;
    margin: 50px 0 100px;
    font-size:16px;

    a {
      color:#F1EDE5;
    }
  }


  ${FormErrorMessage} {
    padding-top: 10px;
    color: #fff;
    font: inherit;

    .img-container {
      display: none;
    }

    @media (max-width: ${breakpoints.mobile}) {
      padding: 0;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    grid-column: 2 / span 6;
    margin-top: 40px;

    form input {
      flex: 3;
    }
  }

  @media (max-width: ${breakpoints.mobileS}) {
    grid-column: 1 / span 2;
    margin-top: 52px;
  }
`
