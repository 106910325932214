import React, { useState, useEffect } from "react";
import Error from "../Error";
import { isEmailValid } from "../../utils/validateEmail";

export interface EmailProps {
  errorMessage: string;
  label: string;
  onChange: (email: string) => void;
  onError: (error: boolean) => void;
}

export const EmailField: React.FC<EmailProps> = ({
  errorMessage,
  label,
  onChange,
  onError,
}) => {
  const [initialState, setInitialState] = useState(true);
  const [errorText, setErrorText] = useState("");
  const [localEmail, setLocalEmail] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (isEmailValid(localEmail)) {
      onChange(localEmail);
      setErrorText("");
      onError(false);
      return;
    }
    if (!initialState) {
      setErrorText(errorMessage);
    }
    onError(true);
  }, [localEmail, errorMessage, onError, onChange]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setInitialState(false);
    setLocalEmail(event.target.value);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    setInitialState(false);
    setErrorText("");
    setIsFocused(true);
  };

  return (
    <div className="email-field">
      <label
        htmlFor="email"
        className={isFocused || localEmail ? "floating" : ""}
      >
        {label}
      </label>
      <input
        key="email-input"
        type="text"
        id="email"
        name="email address"
        placeholder=""
        value={localEmail}
        onChange={event => handleChange(event)}
        onFocus={event => handleFocus(event)}
        className={errorText ? "error" : ""}
      />
      <Error error={errorText} />
    </div>
  );
};

export default EmailField;
