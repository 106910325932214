import React, { useState, useEffect, useCallback } from "react";
import { StyledForm, StyledField, StyledButton } from "./style";
import EmailField from "./components/Email";
import BirthdayField from "./components/Birthday";
import { EmailFormContentstackProps } from "./types";

export const ContentstackEmailForm: React.FC<EmailFormContentstackProps> = ({
  globalConfig,
  pageConfig,
  lang,
}) => {
  let form = {
    // these are page-level configs from Page query
    ...pageConfig,
    // these are global configs from Website Configuration query
    ...globalConfig,
  };

  const [birthdate, setBirthday] = useState("");
  const [email, setEmail] = useState("");
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [hasError, setHasError] = useState(true);
  const [successURL, setSuccessURL] = useState("");
  const [errorURL, setErrorURL] = useState("");
  const [puuid, setPuuid] = useState(null);

  const emailSignupStatusKey = `email-signup-status-${form.salesforce_external_key}`;

  const formatLang = (lang: string) => {
    // Salesforce CRM requires locales to be in the RiotLocale format - e.g. "en_US" instead of "en-us"
    return lang
      .replace("-", "_")
      .replace(/\_(.*)$/g, char => char.toUpperCase());
  };

  const parseNonUSDate = (date: string) => {
    let tokens = date.split("/");
    return `${tokens[1]}/${tokens[0]}/${tokens[2]}`;
  };

  const formatDate = (date: string) => {
    let convertedDate = lang === "en-us" ? date : parseNonUSDate(date);
    return convertedDate.toString();
  };

  const checkSignupStatus = useCallback(() => {
    // Salesforce DEManager sends error messages via query params only. There is no JSON response.
    // Sadly, Salesforce DEManager won't send you a more detailed error message about why exactly it failed.
    // Most common reason for failure I've found from testing: missin external key or successURL/errorURL.
    // Measures to minimize errors:
    // 1) The external key is a required field in the CMS entry.
    // 2) successURL and errorURL are optional fields in the CMS entry but I set successURL and errorURL to default to the current page.
    // 3) Client-side input validation for email and birthdate
    if (
      window.localStorage.getItem(emailSignupStatusKey) &&
      window.localStorage.getItem(emailSignupStatusKey) === "submitted" && // we set localStorage key when the form is submitted
      !window.location.search.includes(
        "errMsg=An+error+occurred+updating+the+data+extension", // Salesforce won't set this query param if the submission was successfully saved
      )
    ) {
      return true;
    }
    window.localStorage.removeItem(emailSignupStatusKey); // TODO: Show user a message if signup failed? We currently only have a "success" message.
    return false;
  }, [emailSignupStatusKey]);

  const handleClick = () => {
    window.localStorage.setItem(emailSignupStatusKey, "submitted");
  };

  useEffect(() => {
    setSuccessURL(
      form.successURL
        ? window.location.origin + form.successURL
        : window.location.href,
    );
    setErrorURL(
      form.errorURL
        ? window.location.origin + form.errorURL
        : window.location.href,
    );

    if (
      // @ts-ignore
      typeof window.RiotBar !== `undefined` &&
      // @ts-ignore
      window.RiotBar.account.getAuthState().sub !== ""
    ) {
      // @ts-ignore
      setPuuid(window.RiotBar.account.getAuthState().sub); // if no puuid is available, Salesforce defaults to using email as the puuid
    }
    setIsSignedUp(checkSignupStatus());
  }, [form, successURL, errorURL, puuid, isSignedUp, checkSignupStatus]);

  return (
    <StyledForm>
      {isSignedUp ? (
        <div className="post-signup">
          <div className="post-signup-content">
            <h1>{form.post_signup_header}</h1>
            {form.post_signup_message && <p>{form.post_signup_message}</p>}
          </div>
        </div>
      ) : (
        <form
          name="subscribeForm"
          className="signup"
          action="https://cl.s10.exct.net/DEManager.aspx"
          method="post"
          encType="application/x-www-form-urlencoded"
        >
          <input type="hidden" name="_clientID" value="100025126" />
          <input
            type="hidden"
            name="_deExternalKey"
            value={form.salesforce_external_key || ""}
          />
          <input type="hidden" name="_action" value="add/update" />
          <input type="hidden" name="_returnXML" value="1" />
          <input type="hidden" name="_successURL" value={successURL} />
          <input type="hidden" name="_errorURL" value={errorURL} />
          <input type="hidden" name="puuid" value={puuid || email} />
          <input
            type="hidden"
            name={form.email_list_name.toLowerCase()}
            value="true"
          />
          {form.age_gating_enabled && (
            <input
              type="hidden"
              name="birth date"
              value={formatDate(birthdate)}
            />
          )}
          <input type="hidden" name="opt_in" value="true" />
          <input
            type="hidden"
            name="language preference"
            value={formatLang(lang)}
          />
          <h1>{form.title}</h1>
          {form.subhead && <p className="subheader-dark">{form.subhead}</p>}
          <StyledField>
            <EmailField
              errorMessage={form.error_messages.invalid_email}
              label={form.email_field_label}
              onChange={(email: string) => setEmail(email)}
              onError={(error: boolean) => setHasError(error)}
            />
          </StyledField>
          {form.age_gating_enabled && (
            <StyledField>
              <BirthdayField
                errorMessage={form.error_messages.invalid_dob}
                label={form.date_of_birth_field_label}
                format={form.date_of_birth_format}
                lang={lang}
                onChange={(birthday: string) => setBirthday(birthday)}
                onError={(error: boolean) => setHasError(error)}
              />
            </StyledField>
          )}
          <StyledButton
            disabled={
              hasError ||
              (form.age_gating_enabled && birthdate === "") ||
              email === ""
            }
            type="submit"
            onClick={handleClick}
          >
            {form.email_cta}
          </StyledButton>
        </form>
      )}
    </StyledForm>
  );
};
