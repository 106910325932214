import React, { useState } from "react";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-awesome-styled-grid";
import { MobileView } from "../Styled";
import Title from "../Commons/Title";
import CardList from "../CardList";
import ForgeLogo from "../../images/forge-shape.png";
import * as S from "./styles";

export const fragment = graphql`
  fragment GamesModuleFragment on Contentstack_home_2_0 {
    gamesModule: games_module {
      title
      games {
        id
        title
        visitSiteCta: visit_site_cta
        websiteUrl: website_url
        flavorCopy: flavor_copy
        buyNowCta: buy_now_cta
        buyNowUrl: buy_now_url
        portraitThumbnail: portrait_thumbnail {
          url
        }
        landscapeThumbnail: landscape_thumbnail {
          url
        }
        logo {
          url
        }
        singleLine: single_line
      }
    }
  }
`;

export type GamesModuleData = {
  title: string;
  games: [
    {
      id: string;
      title: string;
      visitSiteCta: string;
      websiteUrl: string;
      flavorCopy: string;
      buyNowCta: string;
      buyNowUrl: string;
      portraitThumbnail: {
        url: string;
      };
      landscapeThumbnail: {
        url: string;
      };
      logo: {
        url: string;
      };
      singleLine: string;
    },
  ];
};

export type Props = {
  data: GamesModuleData;
  list?: Array<{
    name: string;
    animation_class: string;
    image: string;
    visitSiteCta: string;
    purchase_url: string;
    buyNowCta: string;
    website_url: string;
    status: string;
    types: Array<string>;
  }>;
  clipPath_lg?: any;
  clipPath_md?: any;
  clipPath_sm?: any;
};

const GamesModule: React.FC<Props> = (props: Props) => {
  const { title, games } = props.data;

  const list = games.map((game) => ({
    name: game.title,
    animation_class: "animation-opacity",
    image: game.landscapeThumbnail.url,
    imagePortrait: game.portraitThumbnail.url,
    logo: game.logo.url,
    purchase_url: game.buyNowUrl,
    purchase_text: game.buyNowCta,
    visitSiteCta: game.visitSiteCta,
    buyNowCta: game.buyNowCta,
    website_url: game.websiteUrl,
    website_text: game.visitSiteCta,
    status: game.flavorCopy,
    types: ["ps", "xbox", "steam", "apple", "android"],
  }));

  const [activePlatform, setActivePlatform] = useState("all");

  return (
    <S.Section>
      <Container>
        <Row justify="center">
          <Col xs={2} sm={2} md={8} lg={10} xl={10} align="center">
            <S.TitleWrapper className="animation-opacity">
              <S.Forge logo={ForgeLogo} />
              <Title level={1} variable="primary" color="white" uppercase>
                {title}
              </Title>
            </S.TitleWrapper>
          </Col>
        </Row>
      </Container>

      <CardList
        query={{
          xs: 2,
          sm: 2,
          md: 8,
          lg: 6,
          xl: 6,
        }}
        activePlatform={activePlatform}
        list={list}
      />
      <MobileView style={{ overflow: "hidden", paddingTop: "4px" }}>
        <S.MobBtnWrapper>
          <Row justify="center">
            <Col align="center">
              {/*
              <Button size="large" type="tertiary">
                load more
              </Button>
              */}
            </Col>
          </Row>
        </S.MobBtnWrapper>
      </MobileView>
    </S.Section>
  );
};

export default GamesModule;
