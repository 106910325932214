import styled, { css } from "styled-components";

type SectionType = {
  bg_color: string;
  reverse?: boolean;
  clipPath_lg: string;
  clipPath_md: string;
  clipPath_sm: string;
  first: boolean;
  bg_layer?: string;
  desktopBG: string;
  laptopBG: string;
  halfBreaker?: boolean;
};

type MobileImage = {
  first: boolean;
  ie_image?: string;
};

export const Wrapper = styled.div`
  display: block;
  z-index: 1;
`;

export const DescriptionWrapper = styled.div`
  margin-top: 24px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 52px;
  display: flex;
  justify-content: left;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-top: 56px;
    margin-bottom: 50px;
  }
  @media (max-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    button {
      span {
        padding: 8px 32px;
      }
    }
  }
`;

export const MobileImageWrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

export const MobileImage = styled.div<MobileImage>`
  width: 100%;
  height: auto;
  object-fit: cover;
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% calc(100% - 32px),
    82.18% calc(100% - 40px),
    84.5% calc(100% - 2px),
    0% calc(100% - 44px)
  );

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    object-position: ${(props) => (props?.first ? "top -8vw left 0vw" : "center center")};
    clip-path: ${(props) =>
      props?.first
        ? "polygon( 0% 0%, 100% 0%, 100% calc(100% - 90px), 82.55% calc(100% - 102px), 84.50% calc(100% - 2px), 0% calc(100% - 110px) )"
        : "polygon( 0% 0%,100% 0%,100% calc(100% - 98px),16.66% calc(100%),17.57% calc(100% - 92px),0% calc(100% - 68px) )"};
  }

  img {
    width: 100%;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background-image: url(${(props) => props.ie_image});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
  }
`;

export const Section = styled.section<SectionType>`
  overflow: hidden;
  padding: 0px 0 80px 0px;
  background-color: ${(props) => props.bg_color};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: ${(props) => (props?.reverse ? "right" : "left")};
  clip-path: ${(props) => props.clipPath_sm};
  margin-top: ${(props) => (props?.first ? "-47px" : "-47px")};
  z-index: ${(props) => (props?.first ? "2" : "1")};
  position: relative;

  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 0px 0 80px 0px;
    margin-top: ${(props) => (props?.first ? "-123px" : "-111px")};
    clip-path: ${(props) => props.clipPath_md};
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    clip-path: ${(props) => props.clipPath_lg};
    padding: ${(props) => (props?.first ? "240px 0 300px 0px" : "225px 0 200px 0px;")};
    margin-top: ${(props) => (props?.first ? "-190px" : "-190px")};

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url(${(props) => props.bg_layer});
      pointer-events: none;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: ${(props) => (props?.first ? "auto" : "0")};
      right: 0;
      bottom: 0;
      background-size: ${(props) => (props?.first ? "cover" : "contain")};
      background-repeat: no-repeat;
      background-position: ${(props) => (props?.first ? "center top" : "center top")};
      background-image: url(${(props) => props.laptopBG});
      width: ${(props) => (props?.first ? "50%" : "45%")};
      ${(props) => props.halfBreaker && "clip-path: polygon(43.8% 0,100% 0,100% 100%,18% 100%);"}
      pointer-events: none;
    }
  }
`;

export const PhoneView = styled.div`
  display: block;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    display: none;
  }
`;

export const TabletView = styled.div`
  display: none;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    display: block;
  }
`;

export const leftClipPath_lg = `polygon(
    0% 0%,
    100% 0%,
    100% calc(100%),
    8.75% calc(100% - 189px),
    9.65% calc(100% - 88px),
    0% calc(100% - 104px)
  )`;
export const leftClipPath_md = `polygon(
    0% 0%,
    100% 0%,
    100% 100%,
    0% calc(100% - 110px)
  )`;
export const leftClipPath_sm = `polygon(
    0% 0%,
    100% 0%,
    100% 100%,
    0% calc(100% - 46px)
  )`;

export const rightClipPath_lg = `polygon(
    0% 0%,
    100% 0%,
    100% calc(100% - 28px),
    69.86% calc(100% - 90px),
    71.45% calc(100% - 6px),
    0% calc(100% - 148px)
  )`;
export const rightClipPath_md = `polygon(
    0% 0%,
    100% 0%,
    100% calc(100% - 60px),
    73.30% calc(100% - 88px),
    75.13% calc(100% - 2px),
    0% calc(100% - 78px)
  )`;
export const rightClipPath_sm = `polygon(
    0% 0%,
    100% 0%,
    100% calc(100% - 40px),
    15.31% calc(100% - 0px),
    17.18% calc(100% - 34px),
    0% calc(100% - 28px)
  )`;

export const leftQuery = {
  xs: 2,
  sm: 2,
  md: 5,
  lg: 5,
  xl: 5,
};
export const leftOffset = {
  xs: 0,
  sm: 0,
  md: 2,
  lg: 0,
  xl: 0,
};

export const rightQuery = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 5,
  xl: 5,
};
export const rightOffset = {
  xs: 0,
  sm: 0,
  md: 2,
  lg: 0,
  xl: 0,
};
