import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from "react-awesome-styled-grid";
import Video from "../Video";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Thumbs,
  Navigation,
  Pagination,
  EffectFade,
  Controller,
  Autoplay,
  SwiperOptions,
} from "swiper/core";
import SwiperClass from "swiper/types/swiper-class";

import Paragraph from "../Commons/Paragraph";
import Title from "../Commons/Title";
import Button from "../Commons/Button";
import Modal from "../Modal";

import Android from "../../images/platforms_small/android.svg";
import Apple from "../../images/platforms_small/apple.svg";
import Epic from "../../images/platforms_small/epic.svg";
import Gogcom from "../../images/platforms_small/gogcom.svg";
import Ps from "../../images/platforms_small/ps.svg";
import Steam from "../../images/platforms_small/steam.svg";
import Switch from "../../images/platforms_small/switch.svg";
import Xbox from "../../images/platforms_small/xbox.svg";
import LinkArrow from "../../images/hero/hero-link-arrow.svg";

import LeftArrow from "../../images/left_arrow.svg";
import RightArrow from "../../images/right_arrow.svg";

import {
  ButtonWrapper,
  HeadingWrapper,
  Section,
  SectionBreaker,
  Wrapper,
  WrapperRight,
  VideoArea,
  VideoContainer,
  PlatformList,
  PlatformListItem,
  ParagraphWrapper,
  ImagesWrapper,
  LogoImage,
  FrontImage,
  SwiperWrapper,
  ThumbItem,
  NextButton,
  PrevButton,
  Number,
  Progress,
  ThumbWrapper,
} from "./style";

SwiperCore.use([Thumbs, Navigation, Pagination, EffectFade, Controller, Autoplay]);

const selectOptions = [
  {
    key: "Playstation 1 - Physical",
    value: "Playstation 1 - Physical",
  },
  {
    key: "Playstation 2 - Physical",
    value: "Playstation 2 - Physical",
  },
  {
    key: "Playstation 3 - Physical",
    value: "Playstation 3 - Physical",
  },
  {
    key: "Playstation 4 - Physical",
    value: "Playstation 4 - Physical",
  },
  {
    key: "Playstation 5 - Physical",
    value: "Playstation 5 - Physical",
  },
];
const radioOptions = [
  {
    key: "Playstation 1 - Physical",
    value: "Playstation 1 - Physical",
  },
  {
    key: "Playstation 2 - Physical",
    value: "Playstation 2 - Physical",
  },
  {
    key: "Playstation 3 - Physical",
    value: "Playstation 3 - Physical",
  },
  {
    key: "Playstation 4 - Physical",
    value: "Playstation 4 - Physical",
  },
  {
    key: "Playstation 5 - Physical",
    value: "Playstation 5 - Physical",
  },
];

const platformIconList: any = {
  android: <Android />,
  apple: <Apple />,
  epic: <Epic />,
  gogcom: <Gogcom />,
  ps: <Ps />,
  steam: <Steam />,
  switch: <Switch />,
  xbox: <Xbox />,
};

export const fragment = graphql`
  fragment HeroCarouselFragment on Contentstack_home_2_0 {
    heroCarousel: home_page_hero_carousel {
      gameLogo: game_logo {
        url
      }
      foregroundPngImage: foreground_png_image {
        url
      }
      videoThumb: video_thumb {
        url
      }
      inlineVideo: inline_video {
        url
      }
      videoLink: video_link {
        title
        href
      }
      videoDescriptor: video_descriptor
      gamePlatforms: game_platforms {
        title
        platformLogo: platform_logo {
          url
          svg {
            content
          }
        }
      }
      flavorCopy: flavor_copy
      buyPreorderCta: buy_preorder_cta {
        title
        href
      }
      secondaryTextCta: secondary_text_cta {
        title
        href
      }
      lDesktopBgImage: l_destkop_bg_image {
        url
      }
      mobileBgImage: mobile_bg_image {
        url
      }
    }
  }
`;
export type HeroData = [
  {
    gameLogo: {
      url: string;
    };
    foregroundPngImage: {
      url: string;
    };
    videoThumb: {
      url: string;
    };
    inlineVideo: {
      url: string;
    };
    videoLink: {
      title: string;
      href: string;
    };
    videoDescriptor: string;
    gamePlatforms: [
      {
        title: string;
        platformLogo: {
          url: string;
          svg: {
            content: string;
          };
        };
      },
    ];
    flavorCopy: string;
    buyPreorderCta: {
      title: string;
      href: string;
    };
    secondaryTextCta: {
      title: string;
      href: string;
    };
    lDesktopBgImage: {
      url: string;
    };
    mobileBgImage: {
      url: string;
    };
  },
];

export type HeroType = {
  data: HeroData;
  items?: {
    desktopBG: string;
    laptopBG: string;
    tabletBG: string;
    mobileBG: string;
    logo: string;
    cutout: string;
    status: string;
    subTitle: string;
    cta_text: string;
    cta_link: string;
    platforms: string[];
    iframeLink?: string;
    videoUrl?: string;
  }[];
};

export default function HeroType2(props: HeroType & SwiperOptions) {
  const { data } = props;

  const items = data.map((slide) => ({
    desktopBG: slide.lDesktopBgImage.url,
    laptopBG: slide.lDesktopBgImage.url,
    tabletBG: slide.lDesktopBgImage.url,
    mobileBG: slide.mobileBgImage.url,
    logo: slide.gameLogo.url,
    cutout: slide.foregroundPngImage.url,
    status: slide.videoDescriptor,
    subTitle: slide.flavorCopy,
    ctaText: slide.buyPreorderCta.title,
    ctaLink: slide.buyPreorderCta.href,
    secondaryCtaText: slide.secondaryTextCta.title,
    secondaryCtaLink: slide.secondaryTextCta.href,
    platforms: slide.gamePlatforms,
    iframeLink: slide.videoLink.href,
    videoUrl: slide.inlineVideo.url,
    videoLinkText: slide.videoLink.title,
    videoLinkUrl: slide.videoLink.href,
  }));

  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>();
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const [swiperIndex, setSwiperIndex] = useState<number | string | null>(null);
  const [percentage, setPercentage] = useState<number | string | null>(null);
  const [isStop, setStop] = useState<boolean>(false);

  // const [intervalId, setIntervalId] = useState(null)

  const second = 5;

  return (
    <SwiperWrapper>
      <Swiper
        onSwiper={setSwiperRef}
        slidesPerView={1}
        thumbs={{ swiper: thumbsSwiper }}
        effect="fade"
        allowTouchMove={false}
        fadeEffect={{
          crossFade: true,
        }}
        autoplay={{
          delay: 1000 * second,
          disableOnInteraction: false,
          waitForTransition: false,
        }}
        // autoHeight={true}
        onInit={() => {
          setSwiperIndex(0);
        }}
        onSlideChange={(swiperCore: { realIndex: number | string }) => {
          const { realIndex } = swiperCore;
          setSwiperIndex(realIndex);
        }}
      >
        {items &&
          !!items.length &&
          items?.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <Section>
                  <SectionBreaker
                    className="lazyload"
                    ie_sm={item.mobileBG}
                    ie_md={item.tabletBG}
                    ie_lg={item.laptopBG}
                    ie_xl={item.desktopBG}
                    data-bgset={`${item.desktopBG} [(min-width: 2560px)] | ${item.laptopBG} [(min-width: 1440px)] | ${item.tabletBG} [(min-width: 768px)] | ${item.mobileBG} [(min-width: 320px)] | ${item.mobileBG}`}
                  />
                  <Wrapper>
                    <Container>
                      <Row justify="center">
                        <Col xs={2} sm={2} md={8} lg={6} xl={6}>
                          <ImagesWrapper>
                            <LogoImage ie_image={item.logo}>
                              <img className="lazyload noselect" data-src={item.logo} />
                            </LogoImage>
                            <FrontImage ie_image={item.cutout}>
                              <img className="lazyload noselect" data-src={item.cutout} />
                            </FrontImage>
                          </ImagesWrapper>
                        </Col>

                        <Col xs={2} sm={2} md={8} lg={6} xl={6}>
                          <Row justify="center">
                            <Col xs={2} sm={2} md={6} lg={12} xl={12}>
                              <VideoArea onClick={() => swiperRef?.autoplay.stop()}>
                                <VideoContainer>
                                  <Video src={item.videoUrl} iframeLink={item.iframeLink} title={item.videoLinkText} />
                                </VideoContainer>
                              </VideoArea>
                            </Col>
                            <Col xs={2} sm={2} md={4} lg={12} xl={12}>
                              <WrapperRight>
                                <HeadingWrapper>
                                  <Title color="" level={5} uppercase>
                                    {item.status}
                                  </Title>
                                  <PlatformList>
                                    {item.platforms.map((platform) => (
                                      <PlatformListItem
                                        key={platform.title}
                                        dangerouslySetInnerHTML={{
                                          __html: platform.platformLogo.svg.content,
                                        }}
                                      />
                                    ))}
                                  </PlatformList>
                                </HeadingWrapper>
                                <ParagraphWrapper>
                                  <Paragraph color="base">{item.subTitle}</Paragraph>
                                </ParagraphWrapper>
                                <ButtonWrapper>
                                  <div>
                                    <Button size="large" type={"primary"} as="a" href={item.ctaLink} target="_blank">
                                      {item.ctaText}
                                    </Button>
                                  </div>
                                  {item.secondaryCtaText && item.secondaryCtaLink && (
                                    <a href={item.secondaryCtaLink} className="textLink" target="_blank">
                                      {item.secondaryCtaText}
                                      <LinkArrow />
                                    </a>
                                  )}
                                </ButtonWrapper>
                              </WrapperRight>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </Wrapper>
                </Section>
              </SwiperSlide>
            );
          })}
      </Swiper>
      <ThumbWrapper>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={3}
          allowTouchMove={false}
          watchSlidesVisibility={true}
        >
          <PrevButton
            onClick={() => {
              swiperRef?.slidePrev();
            }}
          >
            <LeftArrow />
          </PrevButton>
          <NextButton
            onClick={() => {
              swiperRef?.slideNext();
            }}
          >
            <RightArrow />
          </NextButton>
          {new Array(items?.length).fill({}).map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <ThumbItem
                  onClick={() => {
                    setStop(true);
                    swiperRef?.autoplay.stop();
                    swiperRef?.update();
                  }}
                >
                  <Number>
                    {index > 10 ? "" : "0"}
                    {index + 1}
                  </Number>
                  <Progress active={swiperIndex === index} second={second} isStop={isStop} />
                </ThumbItem>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </ThumbWrapper>
      {isModelOpen && (
        <Modal
          selectOptions={selectOptions}
          radioOptions={radioOptions}
          setIsModelOpen={setIsModelOpen}
          isModelOpen={isModelOpen}
        />
      )}
    </SwiperWrapper>
  );
}
