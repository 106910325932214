import React from "react";
import styled from "styled-components";
import Button from "../Commons/Button";

type CARD_IMG = {
  image: string;
};

const CardImage = styled.div<CARD_IMG>`
  height: 0;
  padding-top: 56.25%;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background-image: url(${(props) => props.image});
  }
`;

const CardBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 16px 32px 20px;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    transition: all 0.2s ease-in-out;
    margin-top: -40px;
    padding-top: 46px;
    padding-bottom: 30px;
    clip-path: polygon(0 40px, 100% 0, 100% 100%, 0% 100%);
  }
`;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  margin-bottom: 40px;
  border: 1px solid #30353f;
  ${CardImage} {
    transition: background-size 0.2s ease-in-out;
    -moz-transition: background-size 0.2s ease-in-out;
    -ms-transition: background-size 0.2s ease-in-out;
    -o-transition: background-size 0.2s ease-in-out;
    -webkit-transition: background-size 0.2s ease-in-out;
    background-size: 101% 101%;
  }
  &:hover ${CardImage} {
    background-size: 104% 104%;
  }
  &:hover ${CardBody} {
    margin-top: 0px;
    padding-top: 16px;
    padding-bottom: 20px;
    clip-path: polygon(0 0px, 100% 0, 100% 100%, 0% 100%);
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-bottom: 20px;
  }
`;

const CardStatus = styled.p`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: ${(props) => props.theme.variables.secondary.base};
  margin-bottom: 12px;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-bottom: 16px;
  }
`;

const CardActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px -16px;
  button {
    margin: 12px 16px;
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.sm}rem) {
    margin: 0px;
    flex-direction: row;
    a,
    button {
      margin: 0 10px;
    }
  }
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    a,
    button {
      margin: 0 16px;
    }
  }
`;

type Props = {
  name: string;
  image: string;
  purchase_url: string;
  website_url: string;
  status: string;
  buyNowCta: string;
  visitSiteCta: string;
  types: Array<string>;
};

const Card: React.FC<Props> = ({ name, image, purchase_url, website_url, buyNowCta, visitSiteCta, status }) => {
  return (
    <CardContainer>
      <CardImage className="lazyload" image={image} data-bgset={`${image} [(min-width: 1px)]`} />
      <CardBody>
        <CardStatus>{status}</CardStatus>
        <CardActions>
          <Button size="medium" type="primary" as="a" href={purchase_url} target="_blank">
            {buyNowCta}
          </Button>
          <Button size="medium" type="secondary" as="a" href={website_url} target="_blank">
            {visitSiteCta}
          </Button>
        </CardActions>
      </CardBody>
    </CardContainer>
  );
};

export default Card;
