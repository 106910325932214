import React, { useEffect } from "react";
import styled from "styled-components";
import { Container, Row, Col, IOffsetOptions } from "react-awesome-styled-grid";

import Card from "../Card";

const Wrapper = styled.div`
  display: block;
`;

const Section = styled.section`
  padding: 0 0 40px 0;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    padding: 0px 0;
  }
`;

interface ListItem {
  name: string;
  animation_class: string;
  image: string;
  buyNowCta: string;
  visitSiteCta: string;
  purchase_url: string;
  website_url: string;
  status: string;
  types: Array<string>;
}

type Props = {
  list: ListItem[];
  query: Object;
  offset?: IOffsetOptions;
  activePlatform: string;
};

const CardList: React.FC<Props> = ({ list = [], query, offset, activePlatform }) => {
  return (
    <Section>
      <Container>
        <Row>
          {list &&
            !!list.length &&
            list.map((item: ListItem, index) => {
              if (activePlatform !== "all" && !item.types.includes(activePlatform)) return null;
              return (
                <Col key={index} {...query} offset={{ ...offset }}>
                  <Wrapper className={item?.animation_class}>
                    <Card {...item} />
                  </Wrapper>
                </Col>
              );
            })}
        </Row>
      </Container>
    </Section>
  );
};

export default CardList;
