import styled, { css } from "styled-components";
import Title from "../Commons/Title";

type SectionType = {
  clipPath_lg?: any;
  clipPath_md?: any;
  clipPath_sm?: any;
};

type ForgeType = {
  logo: string;
};

export const Section = styled.section<SectionType>`
  padding: 120px 0 50px;
  background-color: ${(props) => props.theme.variables.primary.black};
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 75px;
  ${Title} {
    z-index: 2;
  }
`;

export const Forge = styled.div<ForgeType>`
  background-image: url("${(props) => props.logo}");
  background-repeat: no-repeat;
  background-size: cover;
  width: 41px;
  height: 93px;
  position: absolute;
  left: 50%;
  top: -50%;
  transform: translate(-50%, 0);
  z-index: 1;
  @media (min-width: ${(props) => props.theme.awesomegrid.breakpoints.lg}rem) {
    width: 60px;
    height: 130px;
  }
`;

export const MobBtnWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.awesomegrid.breakpoints.md}rem) {
    button {
      span {
        padding: 8px 32px;
      }
    }
  }
`;
