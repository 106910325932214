export const isEmailValid = (localEmail: string) => {
  // is email field filled?
  if (!localEmail) {
    return false;
  }

  // does email contain characters before and after @
  let validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return validEmailRegex.test(localEmail);
};
